<template>
  <div class="customize-main">
    <table class="customize-form" border="0" cellpadding="0" cellspacing="0">
      <tbody>
        <tr>
          <td class="teding" rowspan="2" colspan="2">
            <img class="teding" src="@/assets/image/teding.jpg" />
          </td>
          <td class="customize-title1" rowspan="2" colspan="6">
            Internal Temporary Manpower Supply Agreement<br />
            内部借工协议
          </td>
          <td class="text-right">Application Date <br />申请日期</td>
          <td class="text-center">{{ TableDate | tableDateFrom }}</td>
        </tr>
        <tr>
          <td class="text-right">Workflow Table S/N <br />表单编号</td>
          <td class="text-center">{{ ApplicationNumber }}</td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">ITMSA No.<br />借工协议编号</td>
          <td class="text-center" colspan="3">{{ ITMSANO }}</td>
          <td class="text-center" colspan="2">
            Duration of Supply<br />
            计划借工期限
          </td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="DurationofSupply"
              type="date"
              placeholder="选择日期"
              :picker-options="pickerOptionsStart"
            >
            </el-date-picker>
          </td>
          <td class="text-center">to</td>
          <td class="text-center">
            <el-date-picker
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              v-model="DurationofSupplyto"
              :picker-options="pickerOptionsEnd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Project A<br />
            用工项目
          </td>
          <td class="text-center" colspan="3">
            <el-select v-model="FirstProject" placeholder="请选择" clearable>
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            Project B<br />
            出工项目
          </td>
          <td class="text-center" colspan="3">
            <el-select v-model="SecondProject" placeholder="请选择" clearable>
              <el-option
                v-for="item in projectList"
                :key="item.ProjectID"
                :label="item.ProjectName"
                :value="item.ProjectID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            Unit A<br />
            用工单位
          </td>
          <td class="text-center" colspan="3">
            <el-select v-model="FirstCompany" placeholder="请选择" clearable>
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            Unit B<br />
            出工单位
          </td>
          <td class="text-center" colspan="3">
            <el-select v-model="SecondCompany" placeholder="请选择" clearable>
              <el-option
                v-for="item in companyList"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="10">
            It's bilaterally agreed on internal temporary manpower supply from
            PART A to PART B as follows:<br />
            经各方友好协商，由乙方向甲方临时提供人力资源，相关主要事项约定如下。
          </td>
        </tr>
        <tr>
          <td class="text-center">
            SN<br />
            序号
          </td>
          <td class="text-center">
            Settlement Type<br />
            结算模式
          </td>
          <td class="text-center" colspan="2">
            Job Profession<br />
            借工人员工种/岗位
          </td>
          <td class="text-center">
            Qualif.<br />
            人员资质
          </td>
          <td class="text-center">
            Qty.<br />
            借工数量
          </td>
          <td class="text-center">
            Rate <br />
            借工单价
          </td>
          <td class="text-center">
            FAT Beared By<br />
            食宿交通提供方
          </td>
          <td class="text-center">
            Rate of Overhead Fee<br />
            管理费比例
          </td>
          <td class="text-center">
            Remarks<br />
            备注
          </td>
        </tr>
        <tr v-for="(item, index) in CostAllocationDetails" :key="index">
          <td class="text-center">{{ index + 1 }}</td>
          <td class="text-center">
            <el-select
              v-model="item.SettlementType"
              placeholder="请选择"
              @change="changeCategory(index)"
              clearable
            >
              <el-option
                v-for="item in opinions2"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center" colspan="2">
            <el-select
              v-model="item.JobProfession"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in positionList"
                :key="item.ID"
                :label="item.PositionsCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.Qulification"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in opinions1"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input-number
              v-model="item.Qty"
              :min="0"
              label="描述文字"
            ></el-input-number>
          </td>
          <td class="text-center">
            <el-input v-model="item.Rate" placeholder="请输入 Enter"></el-input>
          </td>
          <td class="text-center">
            <el-select
              v-model="item.FATBearedBy"
              placeholder="请选择"
              clearable
            >
              <el-option
                v-for="item in opinions3"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </td>
          <td class="text-center">
            <el-input
              v-model="item.RateofOverheadFee"
              placeholder="请输入 Enter"
            ></el-input>
          </td>

          <td class="text-center">
            <el-input
              v-model="item.Remarks"
              placeholder="请输入 Enter"
            ></el-input>
          </td>
        </tr>
        <tr>
          <td class="text-left" colspan="10">Note主要条款:</td>
        </tr>
        <tr>
          <td class="text-center">1</td>
          <td class="text-left" colspan="9">
            乙方按甲方要求提供合格的人力资源，并定期或于用工结束后按本协议约定发起借工费用分割，甲方负责借用人员日常工作安排、考勤和管理，并及时确认费用分割单。
          </td>
        </tr>
        <tr>
          <td class="text-center">2</td>
          <td class="text-left" colspan="9">
            固定小时单价模式下，价格适用所有常规及加班、加点工作。
          </td>
        </tr>
        <tr>
          <td class="text-center">3</td>
          <td class="text-left" colspan="9">
            借工单价/成本费用包含人员工资、各类津补贴（FAT按上表约定）、社保、医保、外事、沙化、动迁、基本PPE费用；借工期间甲方直接发放用工人员的单项奖（如安全奖、质量奖、控制点奖、签证奖等）由甲方承担。
          </td>
        </tr>
        <tr>
          <td class="text-center">4</td>
          <td class="text-left" colspan="9">
            借工期间人员发生伤、病情况的，甲方负责现场和紧急救护，及时通知乙方，并为乙方安排后续处置提供必要的支持和配合；伤、病期间未能履行工作义务的，不做借工费用结算。
          </td>
        </tr>
        <tr>
          <td class="text-center">5</td>
          <td class="text-left" colspan="9">
            借工人员需撤场时，发起撤场方需至少提前7天通知对方。
          </td>
        </tr>
        <tr>
          <td class="text-center">6</td>
          <td class="text-left" colspan="9">
            计划协议期满，借工未能结束，且各方均未提出异议时，协议期限顺延，但顺延最长不得超过原计划期限。
          </td>
        </tr>
        <tr v-for="(item, index) in Terms" :key="`${index}+ID`">
          <td class="text-center">
            {{ index + 7 }}
          </td>
          <td class="text-left" colspan="9">
            <el-input
              @blur="changeEnglishLetter(index)"
              v-model="item.Contents"
              placeholder="请输入Enter"
            />
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2"><h3>Unit单位</h3></td>
          <td class="text-center" colspan="4"><h3>PART A甲方</h3></td>
          <td class="text-center" colspan="4"><h3>PART B乙方</h3></td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Approved by Manager<br />
            单位/部门领导审批
          </td>
          <td class="text-left text-size" colspan="4">
            <el-input
              type="textarea"
              v-model="ApprovedbyManagerRemark"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyManager"
                  :src="ApprovedbyManager"
                />
              </div>
              <div>
                <span>日期Date：</span><span>{{ ApprovedbyManagerDate }}</span>
              </div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Approved by Project Authorized<br />
            项目授权人审批
          </td>
          <td class="text-left text-size" colspan="4">
            <el-input
              type="textarea"
              v-model="ApprovedbyProjectAuthorizedRemark"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbyProjectAuthorized"
                  :src="ApprovedbyProjectAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbyProjectAuthorizedDate }}</span>
              </div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Approved by SNEMEC HR Dept.<br />
            中东公司人力资源部审批
          </td>
          <td class="text-left text-size" colspan="4">
            <el-input
              type="textarea"
              v-model="ApprovedbySNEMECHRDeptRemark"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMECHRDept"
                  :src="ApprovedbySNEMECHRDept"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMECHRDeptDate }}</span>
              </div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>
        <tr>
          <td class="text-center text-size" colspan="2">
            Approved by SNEMEC Authorized<br />
            中东公司授权人审批
          </td>
          <td class="text-left text-size" colspan="4">
            <el-input
              type="textarea"
              v-model="ApprovedbySNEMECAuthorizedRemark"
              :rows="2"
              placeholder="请输入内容"
            >
            </el-input>
            <div class="text-box">
              <div class="sign-box">
                <span>签字Sign：</span
                ><img
                  class="sign"
                  v-if="ApprovedbySNEMECAuthorized"
                  :src="ApprovedbySNEMECAuthorized"
                />
              </div>
              <div>
                <span>日期Date：</span
                ><span>{{ ApprovedbySNEMECAuthorizedDate }}</span>
              </div>
            </div>
          </td>
          <td colspan="4"></td>
        </tr>

        <tr>
          <td class="text-center" colspan="10">
            The following content is only displayed online and is not in the
            print area 以下内容仅线上流转显示，非打印区域
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2" rowspan="2">
            Douments Upload<br />
            材料上传区域
          </td>
          <td class="text-left" colspan="8">
            * Detailed manpower supply agreement should be
            uploaded.详细用工协议通过附件上传。
          </td>
        </tr>
        <tr>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument1">
              <div @click="download(UploadingDocument1)">
                {{ UploadingDocument1 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(1)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment1"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 1<br />
                材料上传1<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument2">
              <div @click="download(UploadingDocument2)">
                {{ UploadingDocument2 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(2)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment2"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 2<br />
                材料上传2<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument3">
              <div @click="download(UploadingDocument3)">
                {{ UploadingDocument3 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(3)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment3"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 3<br />
                材料上传3<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="1">
            <div class="shenpi shenpi-color" v-if="UploadingDocument4">
              <div @click="download(UploadingDocument4)">
                {{ UploadingDocument4 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(4)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment4"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 4<br />
                材料上传4<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
          <td class="text-center" colspan="2">
            <div class="shenpi shenpi-color" v-if="UploadingDocument5">
              <div @click="download(UploadingDocument5)">
                {{ UploadingDocument5 | stringFormater }}
              </div>
              <span><i class="el-icon-delete" @click="deleteItem(5)"></i></span>
            </div>
            <el-upload
              action="#"
              class="card-upload"
              :auto-upload="true"
              :http-request="uploadAttachment5"
              ref="uploadRef"
              v-else
            >
              <div class="shenpi">
                Up loading document 5<br />
                材料上传5<br />
                <span class="shenpi-tips">点击上传</span>
              </div>
            </el-upload>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { getNewDate } from "@/utils/date";
import {
  getProjectAll,
  allPosition,
  allPositionCategory,
  getCompanyAll,
  uploadAttachment,
} from "@/api/user";
import {
  addInternalTemporaryManpowerApplication,
  editInternalTemporaryManpowerApplicationsItem,
} from "@/api/table";
export default {
  filters: {
    tableDateFrom(data) {
      if (data) {
        data = data.slice(0, 10);
      }
      return data;
    },
    projectFormater(id, data) {
      const item = data.find((item) => {
        return item.ProjectID === id;
      });
      return item ? item.ProjectName : "";
    },
    positionFormater(id, data) {
      const item = data.find((item) => {
        return item.ID === id;
      });
      return item ? item.PositionsCHS : null;
    },
    purchaseOrderFrom(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.AbbreviationName : null;
    },
    purchaseOrderFrom2(id, data) {
      const item = data.find((item) => {
        return item.KeyID === id;
      });
      return item ? item.CRNO : null;
    },
    stringFormater(data) {
      let name1 = process.env.VUE_APP_SERVER + "upload/";
      let index1 = data.indexOf(name1);
      data = data.slice(index1 + name1.length, data.length - 1);
      const index2 = data.indexOf("/");
      const index3 = data.lastIndexOf(".");
      data = data.slice(index2 + 1, index3);
      return data;
    },
    englishLetterFrom(id, data) {
      let name = "";
      if (!id && id !== 0) {
        return;
      }
      name = data[id];
      return name;
    },
  },
  data() {
    return {
      TableDate: null,
      ApplicationNumber: null,
      projectList: [],
      subProjectList: [],
      companyList: [],
      positionList: [],
      CostAllocationDetails: [],
      positionCategoryList: [],
      purchaseOrdersList: [],
      ID: null,
      KeyID: null,
      FirstProject: null,
      SecondProject: null,
      FirstCompany: null,
      SecondCompany: null,
      ITMSANO: null,
      DurationofSupply: null,
      DurationofSupplyto: null,
      EditState: 1,
      UploadingDocument1: null,
      UploadingDocument2: null,
      UploadingDocument3: null,
      UploadingDocument4: null,
      UploadingDocument5: null,
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.DurationofSupplyto) {
            return (
              time.getTime() >= new Date(this.DurationofSupplyto).getTime()
            );
          }
        },
      },
      pickerOptionsEnd: {
        disabledDate: (time) => {
          if (this.DurationofSupply) {
            return (
              time.getTime() <=
              new Date(this.DurationofSupply).getTime() - 8.64e7
            );
          }
        },
      },
      costAllocationItem: {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        SettlementType: null,
        SettlementTypeName: null,
        JobProfession: null,
        JobProfessionName: null,
        Qulification: null,
        QulificationName: null,
        Qty: null,
        Rate: null,
        FATBearedBy: null,
        FATBearedByName: null,
        RateofOverheadFee: null,
        Remarks: null,
      },
      opinions1: [
        {
          value: 1,
          label: "Aramco",
        },
        {
          value: 2,
          label: "General",
        },
        {
          value: 3,
          label: "Sabic ",
        },
      ],
      opinions2: [
        {
          value: 1,
          label: "固定小时单价",
        },
        {
          value: 2,
          label: "实发成本+管理费",
        },
      ],
      opinions3: [
        {
          value: 1,
          label: "用工方",
        },
        {
          value: 2,
          label: "出工方",
        },
      ],

      Terms: [
        {
          ID: null,
          KeyID: null,
          MainKeyID: null,
          Contents: null,
        },
      ],

      ApprovedbyManager: null,
      ApprovedbyManagerRemark: null,
      ApprovedbyManagerDate: null,

      ApprovedbyProjectAuthorized: null,
      ApprovedbyProjectAuthorizedRemark: null,
      ApprovedbyProjectAuthorizedDate: null,

      ApprovedbySNEMECHRDept: null,
      ApprovedbySNEMECHRDeptRemark: null,
      ApprovedbySNEMECHRDeptDate: null,

      ApprovedbySNEMECAuthorized: null,
      ApprovedbySNEMECAuthorizedRemark: null,
      ApprovedbySNEMECAuthorizedDate: null,
    };
  },
  methods: {
    getNew() {
      this.TableDate = getNewDate();
    },
    addCostAllocation(EditState) {
      const data = {
        ID: this.ID,
        KeyID: this.KeyID,
        FirstProject: this.FirstProject,
        SecondProject: this.SecondProject,
        FirstCompany: this.FirstCompany,
        SecondCompany: this.SecondCompany,
        ITMSANO: this.ITMSANO,
        DurationofSupply: this.DurationofSupply,
        DurationofSupplyto: this.DurationofSupplyto,
        EditState,
        UploadingDocument1: this.UploadingDocument1,
        UploadingDocument2: this.UploadingDocument2,
        UploadingDocument3: this.UploadingDocument3,
        UploadingDocument4: this.UploadingDocument4,
        UploadingDocument5: this.UploadingDocument5,
        Terms: this.Terms,
        Details: [],
      };
      this.CostAllocationDetails.forEach((item) => {
        if (item.SettlementType && item.JobProfession) {
          data.Details.push(JSON.parse(JSON.stringify(item)));
        }
      });

      if (!this.ID) {
        addInternalTemporaryManpowerApplication(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("新建成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      } else {
        editInternalTemporaryManpowerApplicationsItem(data).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.$emit("update");
            this.$emit("close");
          }
        });
      }
    },
    geteditData(params) {
      console.log("params", params);

      this.ApplicationNumber = params.ApplicationNumber;
      this.TableDate = params.CreateDate;
      this.ID = params.ID;
      this.KeyID = params.KeyID;
      this.FirstProject = params.FirstProject;
      this.SecondProject = params.SecondProject;
      this.FirstCompany = params.FirstCompany;
      this.SecondCompany = params.SecondCompany;
      this.ITMSANO = params.ITMSANO;
      this.DurationofSupply = params.DurationofSupply;
      this.DurationofSupplyto = params.DurationofSupplyto;
      this.UploadingDocument1 = params.UploadingDocument1;
      this.UploadingDocument2 = params.UploadingDocument2;
      this.UploadingDocument3 = params.UploadingDocument3;
      this.UploadingDocument4 = params.UploadingDocument4;
      this.UploadingDocument5 = params.UploadingDocument5;
      this.Terms = params.Terms;
      params.Details.forEach((_, index, Array) => {
        if (index > 9) {
          if (params.Details[index]) {
            params.Details[index].Category = params.Details[index].CategoryID;
            params.Details[index].ReferencePONO =
              params.Details[index].ReferencePONO;
            this.CostAllocationDetails.push(
              JSON.parse(JSON.stringify(params.Details[index]))
            );
          }
        } else {
          if (params.Details[index]) {
            params.Details[index].Category = params.Details[index].CategoryID;
            this.CostAllocationDetails[index] = JSON.parse(
              JSON.stringify(params.Details[index])
            );
          }
        }
      });
      this.CostAllocationDetails = this.CostAllocationDetails.concat();

      this.ApprovedbyManager = params.ApprovedbyManager;
      this.ApprovedbyManagerRemark = params.ApprovedbyManagerRemark;
      this.ApprovedbyManagerDate = params.ApprovedbyManagerDate;

      this.ApprovedbyProjectAuthorized = params.ApprovedbyProjectAuthorized;
      this.ApprovedbyProjectAuthorizedRemark =
        params.ApprovedbyProjectAuthorizedRemark;
      this.ApprovedbyProjectAuthorizedDate =
        params.ApprovedbyProjectAuthorizedDate;

      this.ApprovedbySNEMECHRDept = params.ApprovedbySNEMECHRDept;
      this.ApprovedbySNEMECHRDeptRemark = params.ApprovedbySNEMECHRDeptRemark;
      this.ApprovedbySNEMECHRDeptDate = params.ApprovedbySNEMECHRDeptDate;

      this.ApprovedbySNEMECAuthorized = params.ApprovedbySNEMECAuthorized;
      this.ApprovedbySNEMECAuthorizedRemark =
        params.ApprovedbySNEMECAuthorizedRemark;
      this.ApprovedbySNEMECAuthorizedDate =
        params.ApprovedbySNEMECAuthorizedDate;
    },
    clearData() {
      this.ApplicationNumber = null;
      this.TableDate = null;
      this.ID = null;
      this.KeyID = null;
      this.FirstProject = null;
      this.SecondProject = null;
      this.FirstCompany = null;
      this.SecondCompany = null;
      this.ITMSANO = null;
      this.DurationofSupply = null;
      this.DurationofSupplyto = null;
      this.UploadingDocument1 = null;
      this.UploadingDocument2 = null;
      this.UploadingDocument3 = null;
      this.UploadingDocument4 = null;
      this.UploadingDocument5 = null;
      this.subProjectList = [];
      this.Terms = [
        {
          ID: null,
          KeyID: null,
          MainKeyID: null,
          Contents: null,
        },
      ];
      this.CostAllocationDetails = [];
      for (let i = 0; i < 10; i++) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }

      this.ApprovedbyManager = null;
      this.ApprovedbyManagerRemark = null;
      this.ApprovedbyManagerDate = null;

      this.ApprovedbyProjectAuthorized = null;
      this.ApprovedbyProjectAuthorizedRemark = null;
      this.ApprovedbyProjectAuthorizedDate = null;

      this.ApprovedbySNEMECHRDept = null;
      this.ApprovedbySNEMECHRDeptRemark = null;
      this.ApprovedbySNEMECHRDeptRemark = null;

      this.ApprovedbySNEMECAuthorized = null;
      this.ApprovedbySNEMECAuthorizedRemark = null;
      this.ApprovedbySNEMECAuthorizedDate = null;
    },
    changeCategory(index) {
      if (index === this.CostAllocationDetails.length - 1) {
        const costAllocationItem = JSON.parse(
          JSON.stringify(this.costAllocationItem)
        );
        this.CostAllocationDetails.push(costAllocationItem);
      }
    },

    changeEnglishLetter(index) {
      const item = {
        ID: null,
        KeyID: null,
        MainKeyID: null,
        Contents: null,
      };
      if (index === this.Terms.length - 1 && this.Terms[index].Contents) {
        this.Terms.push(item);
      }

      if (index === this.Terms.length - 2 && !this.Terms[index].Contents) {
        this.Terms.pop();
      }
    },

    deleteItem(index) {
      switch (index) {
        case 1:
          this.UploadingDocument1 = null;
          break;
        case 2:
          this.UploadingDocument2 = null;
          break;
        case 3:
          this.UploadingDocument3 = null;
          break;
        case 4:
          this.UploadingDocument4 = null;
          break;
        case 5:
          this.UploadingDocument5 = null;
          break;
      }
    },
    uploadAttachment1(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument1 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment2(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument2 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment3(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument3 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment4(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument4 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    uploadAttachment5(file) {
      const data = file.file;
      var img = data.name.substring(data.name.lastIndexOf(".") + 1);
      const suffix = img === "jpg";
      const suffix2 = img === "png";
      const suffix3 = img === "jpeg";
      const suffix4 = img === "pdf";
      const suffix5 = img === "xls";
      const suffix6 = img === "xlsx";
      const suffix7 = img === "rar";
      const suffix8 = img === "zip";
      if (
        !suffix &&
        !suffix2 &&
        !suffix3 &&
        !suffix4 &&
        !suffix5 &&
        !suffix6 &&
        !suffix7 &&
        !suffix8
      ) {
        this.$message.error(
          "只能上传后缀为jpg、png、jpeg、pdf、xls、xlsx、rar、zip的文件!"
        );
        return;
      }
      const formData = new FormData();
      formData.append("Files", data);
      uploadAttachment(formData).then((res) => {
        if (res.status === 200) {
          this.UploadingDocument5 =
            process.env.VUE_APP_SERVER + "upload/" + res.response;
        }
      });
    },
    download(data) {
      console.log("data");
      location.href = data;
    },
  },
  created() {
    this.TableDate = getNewDate();
    for (let i = 0; i < 10; i++) {
      const costAllocationItem = JSON.parse(
        JSON.stringify(this.costAllocationItem)
      );
      this.CostAllocationDetails.push(costAllocationItem);
    }
    getProjectAll().then((res) => {
      if (res.status === 200) {
        this.projectList = res.response;
      }
    });
    getCompanyAll().then((res) => {
      if (res.status === 200) {
        this.companyList = res.response;
      }
    });
    //岗位
    allPosition().then((res) => {
      if (res.status === 200) {
        this.positionList = res.response;
      }
    });
    //岗位类别
    allPositionCategory().then((res) => {
      if (res.status === 200) {
        this.positionCategoryList = res.response;
      }
    });
  },
};
</script>
<style lang="scss" scoped>
.customize-main {
  width: 100%;
  color: #000;
  .teding {
    max-width: 100%;
  }
  .customize-title1 {
    font-size: 24px;
    text-align: center;
    color: #000;
    font-weight: bolder;
  }
  .customize-herder {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 12px;
  }
  .customize-footer {
    display: flex;
    justify-content: space-around;
    font-size: 16px;
    color: #000;
    height: 36px;
    align-items: center;
    margin-top: 24px;
    .text-box {
      text-align: center;
      div {
        margin-bottom: 8px;
      }
    }
  }
  .tr-active {
    background-color: #ecf5ff;
  }
  .text-size {
    font-size: 16px;
    color: #000;
    text-align: center;
    max-width: 200px;
    .el-checkbox {
      margin: 10px 0;
    }
    span {
      margin-right: 20px;
    }
    div {
      margin: 10px 0;
    }
    .input-box {
      display: inline-block;
      width: 100px;
      border-bottom: 1px solid #000;
      margin: 10px 0;
    }
  }
  .text-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 50%;
    }
    .sign-box {
      display: flex;
      align-items: center;
    }
  }
  .customize-form {
    font-size: 11px;
    width: 100%;
    border-left: 1px solid black;
    border-top: 1px solid black;
    margin-top: 12px;
    tr td {
      border-right: 1px solid black;
      border-bottom: 1px solid black;
    }
    .text-center {
      text-align: center;
      font-size: 13px;
      padding: 5px;
      .el-select {
        width: 100%;
      }
      .el-input {
        max-width: 100%;
      }
      .el-date-editor {
        width: 100%;
      }
      .el-cascader {
        width: 100%;
      }
    }
    .text-title {
      text-align: center;
      font-size: 13px;
      .title {
        height: 30px;
        line-height: 30px;
        border-bottom: 1px solid #e0e0e0;
      }
      .img-box {
        padding: 10px;
        min-height: 100px;
      }
      .img-box >>> img {
        width: 100%;
      }
    }
    .text-right {
      text-align: right;
      font-size: 13px;
      padding: 5px;
    }
    .text-left {
      text-align: left;
      font-size: 13px;
      padding: 5px;
    }
  }
  .customize-form2 {
    border-top: none;
    margin-top: 0;
  }
}
.shenpi {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: column;
  cursor: pointer;
  .shenpi-tips {
    color: #315efb;
    margin-left: 0;
    margin-top: 3px;
  }
}
.shenpi-color {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  flex-direction: row;
  color: #315efb;
  cursor: pointer;
  span {
    color: red;
    margin-left: 10px;
  }
}
</style>
<style scoped>
/* .customize-main >>> .el-input__inner { */
/* border: none; */
/* } */
.card-upload >>> .el-upload-list {
  display: none;
}
.input-box >>> .el-input__inner {
  border: none;
  padding: 0;
}
.text-size >>> .el-checkbox__input {
  margin-right: 10px;
}
.signature {
  max-width: 100px;
}
.felx-box {
  display: flex;
  align-items: center;
}
.img-box {
  max-width: 100px;
  margin-left: 10px;
}
.img-box >>> img {
  width: 100%;
}

.tr-active >>> .el-input__inner {
  background-color: #ecf5ff;
}
.sign {
  max-width: 200px;
}
</style>